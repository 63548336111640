import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import nft from '../assets/coin.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Story extends Component {

    render() {
        return (

            <div class="boxWrap2Story2">

                <div class="about">
                    <div class="storyCon">
                        <div class="conT">$MKK LAUNCH</div>
                        <p>In the vibrant landscape of Crazy Monkey King, the launch of the native currency, $MKK, heralds a new era of
                            economic dynamism, strategic opportunities, and versatile utility. As players step into this immersive
                            universe, the introduction of $MKK adds depth to their journey, allowing them to engage with the game's
                            features and rewards in innovative ways.</p>

                        <p>$MKK serves as the principal currency within the Crazy Monkey King ecosystem. This digital currency is
                            designed to facilitate transactions, interactions, and engagement, becoming an essential tool for players to
                            navigate the game's multifaceted world.
                        </p>
                        <button class="button-87" >JOIN PRIVATE SALE</button>
                    </div>

                    <img src={nft} />

                </div>

            </div>
        )
    }
}

export default Story;

