import React, { useEffect, useMemo, useState } from 'react';

const Progressbar = ({ round1Supply, round2Supply, round3Supply, round1Minted, round2Minted, round3Minted }) => {
	/*const [progress, setProgress] = useState([
	  {
		supply: round1Supply,
		minted: round1Minted,
		color: '#007bff',
	  },
	  {
		supply: round2Supply,
		minted: round2Minted,
		color: '#5cb85c',
	  },
	  {
		supply: round3Supply,
		minted: round3Minted,
		color: '#d95b43',
	  },
	]);*/

	const [totalTokens, setTotalTokens] = useState(10000000)


	const [progress, setProgress] = useState([
		{
			round: "Round1",
			supply: round1Supply,
			minted: /*round1Minted*/round1Minted,
			color: '#f5c94b',
			color2: '#fadd8e',
			rgb: 'rgb(245,201,75)',
			gradient: 'linear-gradient(94deg, rgba(245,201,75,1) 0%, rgba(245,184,75,1) 50%, rgba(245,153,75,1) 100%)',
		},
		{
			round: "Round2",
			supply: round2Supply,
			minted: round2Minted,
			color: '#ee7053',
			rgb: 'rgb(238,112,83)',
			gradient: 'linear-gradient(94deg, rgba(238,112,83,1) 0%, rgba(238,99,83,1) 50%, rgba(238,83,83,1) 100%)',
		},
		{
			round: "Round3",
			supply: round3Supply,
			minted: round3Minted,
			color: '#7a4b91',
			color2: '#a66fc2',
			rgb: 'rgb(122,75,145)',
			gradient: 'linear-gradient(94deg, rgba(122,75,145,1) 0%, rgba(126,53,162,1) 50%, rgba(99,6,144,1) 100%)',
		},
	]);


	useEffect(() => {

		//console.log("Progressround1Minted : " + round1Minted);
		//console.log("Progressround2Minted : " + round2Minted);
		//console.log("Progressround3Minted : " + round3Minted);

		//console.log("Progressround1Minted : " + round1Supply);
		//console.log("Progressround2Minted : " + round2Supply);
		//console.log("Progressround3Minted : " + round3Supply);
		setTotalTokens(round1Supply + round2Supply + round3Supply);


	},
		[round1Minted, round1Supply, round2Minted, round2Supply, round3Minted, round3Supply]);

	const handleChange = (index, value) => {
		setProgress((prevProgress) => {
			prevProgress[index] = value;
			return prevProgress;
		});
	};

	return (
		<div class="proMain">
			<div class="progress-bar">
				{progress.map((item, index) => (
					<div key={index} class="progress-bar-part"
						style={{
							width: `${(item.minted / totalTokens) * 100}%`,
							// eslint-disable-next-line no-dupe-keys
							background: item.rgb,background:item.gradient
						}}>

					</div>
				))}
			</div>

			<div class="progress-bar2">
				{progress.map((item, index) => (
					<div key={index} class="progress-bar-part"
						style={{
							width: `${(item.supply / totalTokens) * 100}%`,
							background: item.color
						}}>

					</div>
				))}
			</div>

			<div class="roundsMain">
				<div class="rounds">🟡 Round 1</div>
				<div class="rounds">🟠 Round 2</div>
				<div class="rounds">🟣 Round 3</div>
			</div>
		</div>
	);
};

export default Progressbar;