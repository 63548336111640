/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */

import React, { useEffect, useMemo, useState } from 'react';

import '../App.css';
import nft from '../assets/CH_01_00000.png';
import Footer from '../components/footer';
import navbar from '../assets/navigation-bar.png';
import wrong from '../assets/wrong.png';
import bannerLine from '../assets/0_3.jpg';
import wallet from '../assets/wallet.png';
import money from '../assets/money-bag.png';
import logo from '../assets/logo.jpg';
import $ from 'jquery';
import { Web3Button, Web3Modal, useWeb3Modal } from '@web3modal/react';
import { mainnet, useAccount, useContractRead, useContractReads, useContractWrite, useNetwork, usePublicClient, useSwitchNetwork } from 'wagmi';
import { createPublicClient, formatEther, http, parseEther } from 'viem';
import About from '../components/about';
import Benefits from '../components/benefits';
import { baseGoerli } from 'wagmi/chains';
import Countdown from 'react-countdown';
import '../counter.css';
import Round1 from '../components/round1';
import Round2 from '../components/round2';
import Round3 from '../components/round3';
import { Bar } from 'react-chartjs-2';
import ProgressChart from '../components/progress';
import verify from '../assets/verified-account.png'

var Scroll = require('react-scroll');

$(window).on('load', function () {
	setTimeout(function () { // allowing 3 secs to fade out loader
		$('.page-loader').fadeOut('slow');
	}, 2000);
});

const renderer = ({ days, hours, minutes, seconds, completed }) => {
	/*	if (completed) {
			// Render a completed state
			return <Completionist />;
		} else {*/
	// Render a countdowns

	if (days == 0 && hours == 0 && minutes == 0 && seconds == 0) {

		window.location.reload(true);
		//console.log("Mint Begins");
	}


	return <div class="counterBlock"><div class="days">{days}</div><div class="dots">:</div><div class="days">{hours}</div><div class="dots">:</div><div class="days">{minutes}</div><div class="dots">:</div><div class="sec">{seconds}</div></div>;
	/*	}*/
};

let signer;
var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

const docs = () => {
	window.open("https://crazy.monkeysking.com/");
}

const website = () => {
	window.open("https://monkeysking.com/");
}

let ABI = [
	{
		"inputs": [
			{
				"internalType": "address[]",
				"name": "accounts",
				"type": "address[]"
			},
			{
				"internalType": "uint256[]",
				"name": "amounts",
				"type": "uint256[]"
			}
		],
		"name": "airdrop",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "burn",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "requestedDecrease",
				"type": "uint256"
			}
		],
		"name": "decreaseAllowance",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "currentAllowance",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "requestedDecrease",
				"type": "uint256"
			}
		],
		"name": "ERC20FailedDecreaseAllowance",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "allowance",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "needed",
				"type": "uint256"
			}
		],
		"name": "ERC20InsufficientAllowance",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "sender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "balance",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "needed",
				"type": "uint256"
			}
		],
		"name": "ERC20InsufficientBalance",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "approver",
				"type": "address"
			}
		],
		"name": "ERC20InvalidApprover",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "receiver",
				"type": "address"
			}
		],
		"name": "ERC20InvalidReceiver",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "sender",
				"type": "address"
			}
		],
		"name": "ERC20InvalidSender",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			}
		],
		"name": "ERC20InvalidSpender",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "addedValue",
				"type": "uint256"
			}
		],
		"name": "increaseAllowance",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "OwnableInvalidOwner",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "OwnableUnauthorizedAccount",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "private2Sale",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "privateSale",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "publicSale",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "email",
				"type": "string"
			}
		],
		"name": "regsiter",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_round1EndDate",
				"type": "uint256"
			}
		],
		"name": "set_round1EndDate",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_round1Price",
				"type": "uint256"
			}
		],
		"name": "set_round1Price",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_round1Quota",
				"type": "uint256"
			}
		],
		"name": "set_round1Quota",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_round1StartDate",
				"type": "uint256"
			}
		],
		"name": "set_round1StartDate",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_round2EndDate",
				"type": "uint256"
			}
		],
		"name": "set_round2EndDate",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_round2Price",
				"type": "uint256"
			}
		],
		"name": "set_round2Price",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_round2Quota",
				"type": "uint256"
			}
		],
		"name": "set_round2Quota",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_round2StartDate",
				"type": "uint256"
			}
		],
		"name": "set_round2StartDate",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_round3EndDate",
				"type": "uint256"
			}
		],
		"name": "set_round3EndDate",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_round3Price",
				"type": "uint256"
			}
		],
		"name": "set_round3Price",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_round3Quota",
				"type": "uint256"
			}
		],
		"name": "set_round3Quota",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_round3StartDate",
				"type": "uint256"
			}
		],
		"name": "set_round3StartDate",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "transfer",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			}
		],
		"name": "allowance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimals",
		"outputs": [
			{
				"internalType": "uint8",
				"name": "",
				"type": "uint8"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "emails",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "registerEmail",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "round1CollectedETH",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "round1EndDate",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "round1Minted",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "round1Price",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "round1Quota",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "round1StartDate",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "round2CollectedETH",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "round2EndDate",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "round2Minted",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "round2Price",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "round2Quota",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "round2StartDate",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "round3CollectedETH",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "round3EndDate",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "round3Minted",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "round3Price",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "round3Quota",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "round3StartDate",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "walletAddresses",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

let address = "0xECe8B3563ACDC0D16016D4dc5f79715A22dc8e78";


const IDO = () => {

	const contract = {
		address: address,
		abi: ABI
	}

	const { open } = useWeb3Modal()
	const { chain } = useNetwork()
	const { switchNetwork } = useSwitchNetwork()

	const { address: walletAddress } = useAccount({
		async onConnect() {
			handleConnect()
		}
	})

	const publicClient = createPublicClient({
		chain: baseGoerli,
		transport: http()
	})

	/*const { writeAsync } = useContractWrite({
		address: address,
		abi: ABI,
		functionName: 'publicSale',
		args: ['100000000000000000'],
	})*/


	async function navbarOpen() {

		var res = await writeAsync({
			args: [nftMintingAmount],
			value: parseEther(mintCost)// mintCost
		})
	}

	const [statusError, setstatusError] = useState("")
	const [statusLoading, setstatusLoading] = useState("")
	const [success, setsuccess] = useState("")
	const [_owner, set_owner] = useState("")
	const [_round1Quota, set_round1Quota] = useState("")
	const [_round2Quota, set_round2Quota] = useState("")
	const [_round3Quota, set_round3Quota] = useState("")
	const [_round1Price, set_round1Price] = useState("")
	const [_round2Price, set_round2Price] = useState("")
	const [_round3Price, set_round3Price] = useState("")
	const [_round1Minted, set_round1Minted] = useState("")
	const [_round2Minted, set_round2Minted] = useState("")
	const [_round3Minted, set_round3Minted] = useState("")
	const [_round1StartDate, set_round1StartDate] = useState("")
	const [_formattedDate1, set_formattedDate1] = useState("")
	const [_formattedDate2, set_formattedDate2] = useState("")
	const [_formattedDate3, set_formattedDate3] = useState("")
	const [_round2StartDate, set_round2StartDate] = useState("")
	const [_round3StartDate, set_round3StartDate] = useState("")
	const [_round1EndDate, set_round1EndDate] = useState(0)
	const [_round2EndDate, set_round2EndDate] = useState(0)
	const [_round3EndDate, set_round3EndDate] = useState(0)
	const [_round1CollectedETH, set_round1CollectedETH] = useState("")
	const [_round2CollectedETH, set_round2CollectedETH] = useState("")
	const [_round3CollectedETH, set_round3CollectedETH] = useState("")
	const [_navbarOpen, set_navbarOpen] = useState(0)
	const [_connected, setConnected] = useState(false)
	const [_salesAmount1, set_salesAmount1] = useState("")
	const [_round1StartStatus, set_round1StartStatus] = useState(true)
	const [_round2StartStatus, set_round2StartStatus] = useState(true)
	const [_round3StartStatus, set_round3StartStatus] = useState(true)
	const [_salesAmount2, set_salesAmount2] = useState("")
	const [_salesAmount3, set_salesAmount3] = useState("")
	const [_remainingTokens1, set_remainingTokens1] = useState("")
	const [_remainingTokens2, set_remainingTokens2] = useState("")
	const [_remainingTokens3, set_remainingTokens3] = useState("")


	const [_complete1, setComplete1] = useState(false)
	const [_complete2, setComplete2] = useState(false)
	const [_complete3, setComplete3] = useState(false)

	//.......set1.......//

	const [_hours, set_hours] = useState("00")
	const [_minutes, set_minutes] = useState("00")
	const [_seconds, set_seconds] = useState("00")

	const [_hours2, set_hours2] = useState("00")
	const [_minutes2, set_minutes2] = useState("00")
	const [_seconds2, set_seconds2] = useState("00")

	const [_hours3, set_hours3] = useState("00")
	const [_minutes3, set_minutes3] = useState("00")
	const [_seconds3, set_seconds3] = useState("00")

	const [_stateNow, set_stateNow] = useState(Date.now());
	const [intervalId, setIntervalId] = useState(null); // State variable to store the interval ID

	//.......set1.......//




	// eslint-disable-next-line react-hooks/exhaustive-deps
	async function updateCountdown() {
		const now = new Date();
		const millisecondsSinceEpoch = now.getTime();
		console.log("millisecondsSinceEpoch : " + millisecondsSinceEpoch);
		var timeLeft;

		/*	const options = {
				locale: 'en-US',
				dateStyle: 'full',
				timeStyle: 'long',
			  };*/

		const options = { timeStyle: 'short' };




		//console.log("StateNow : " + millisecondsSinceEpoch);
		//console.log("Number(_round2EndDate) : " + (Number(_round2EndDate)));
		let round2 = (Number(_round2EndDate)) - Number(millisecondsSinceEpoch);
		//console.log("_round2EndDate : " + _round2EndDate);
		//console.log("round2 :"+ round2);
		//timeLeft = Math.max(0, round1);

		//console.log("timeLeft : " + timeLeft);

		console.log("round2StartDate : " + Number(_round2StartDate));

		let round1 = Math.max(0, (Number(_round1EndDate)) - Number(millisecondsSinceEpoch));
		console.log("round1 : " + round1);

		//let newDate = new Date(_round1StartDate);
		const newDate = new Date(_round1StartDate)// + (new Date(_round1StartDate).getTimezoneOffset() * 60000));

		console.log("newDate : " + newDate);

		const newDate2 = new Date(_round2StartDate)// + (new Date(_round2StartDate).getTimezoneOffset() * 60000));
		console.log("newDate2 : " + newDate2);

		const newDate3 = new Date(_round3StartDate)// + (new Date(_round3StartDate).getTimezoneOffset() * 60000));
		console.log("newDate3 : " + newDate3);

		const formattedDate1 = newDate.toLocaleString("en-US", {
			timeZone: "UTC",
			format: "MMMM d, YYYY h:mm a",
		});

		const formattedDate2 = newDate2.toLocaleString("en-US", {
			timeZone: "UTC",
			format: "MMMM d, YYYY h:mm a",
		});

		const formattedDate3 = newDate3.toLocaleString("en-US", {
			timeZone: "UTC",
			format: "MMMM d, YYYY h:mm a",
		});

		set_formattedDate1(formattedDate1)
		console.log("formattedDate : " + formattedDate1);

		set_formattedDate2(formattedDate2)
		console.log("formattedDate2 : " + formattedDate2);

		set_formattedDate3(formattedDate3)
		console.log("formattedDate3 : " + formattedDate3);


		if (millisecondsSinceEpoch < _round1StartDate) {
			set_round1StartStatus(false)
		}

		if (millisecondsSinceEpoch < _round2StartDate) {
			set_round2StartStatus(false)
		}

		if (millisecondsSinceEpoch < _round3StartDate) {
			set_round3StartStatus(false)
		}

		if (round1 === 0) {
			setComplete1(true);
		}

		let round2_1 = Math.max(0, (Number(_round2EndDate)) - Number(millisecondsSinceEpoch));
		console.log("round2 : " + round2_1);

		if (millisecondsSinceEpoch < _round2StartDate) {
			set_round2StartStatus(false)
		}

		if (round2_1 === 0) {
			setComplete2(true);
		}

		let round3 = Math.max(0, (Number(_round3EndDate)) - Number(millisecondsSinceEpoch));
		console.log("round3 : " + round3);

		if (millisecondsSinceEpoch < _round3StartDate) {
			set_round3StartStatus(false)
		}


		if (round3 === 0) {
			setComplete3(true);
		}

		console.log("round1EndDate : " + _round1EndDate);
		console.log("round2EndDate : " + _round2EndDate);
		console.log("round3EndDate : " + _round3EndDate);

		if ((Number(_round1EndDate)) >= Number(millisecondsSinceEpoch) && ((Number(_round1StartDate)) <= Number(millisecondsSinceEpoch))) {
			timeLeft = Math.max(0, (Number(_round1EndDate)) - Number(millisecondsSinceEpoch));
			console.log("timeLeft--1 : ");



			const hours = Math.floor(timeLeft / (1000 * 60 * 60));
			const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
			const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

			set_hours(Number(hours < 10 ? '0' + hours : hours))
			set_minutes(Number(minutes < 10 ? '0' + minutes : minutes))
			set_seconds(Number(seconds < 10 ? '0' + seconds : seconds))

			console.log("hrs : " + hours);
			console.log("mins : " + minutes);
			console.log("sec : " + seconds);

			if (timeLeft <= 0) {

				clearInterval(intervalId);
				//window.location.reload(true);
			}


		} else if (((Number(_round2EndDate)) >= Number(millisecondsSinceEpoch)) && ((Number(_round2StartDate)) < Number(millisecondsSinceEpoch))) {

			timeLeft = Math.max(0, (Number(_round2EndDate)) - Number(millisecondsSinceEpoch));
			console.log("timeLeft--2 : ");

			const hours2 = Math.floor(timeLeft / (1000 * 60 * 60));
			const minutes2 = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
			const seconds2 = Math.floor((timeLeft % (1000 * 60)) / 1000);

			set_hours2(Number(hours2 < 10 ? '0' + hours2 : hours2))
			set_minutes2(Number(minutes2 < 10 ? '0' + minutes2 : minutes2))
			set_seconds2(Number(seconds2 < 10 ? '0' + seconds2 : seconds2))

			console.log("hrs2 : " + hours2);
			console.log("mins2 : " + minutes2);
			console.log("sec2 : " + seconds2);

			if (timeLeft <= 0) {

				clearInterval(intervalId);
				//window.location.reload(true);
			}







		} else if (((Number(_round3EndDate)) >= Number(millisecondsSinceEpoch)) && ((Number(_round3StartDate)) < Number(millisecondsSinceEpoch))) {

			timeLeft = Math.max(0, (Number(_round3EndDate)) - Number(millisecondsSinceEpoch));
			console.log("timeLeft--3 : ");

			const hours3 = Math.floor(timeLeft / (1000 * 60 * 60));
			const minutes3 = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
			const seconds3 = Math.floor((timeLeft % (1000 * 60)) / 1000);

			set_hours3(Number(hours3 < 10 ? '0' + hours3 : hours3))
			set_minutes3(Number(minutes3 < 10 ? '0' + minutes3 : minutes3))
			set_seconds3(Number(seconds3 < 10 ? '0' + seconds3 : seconds3))

			console.log("hrs3 : " + hours3);
			console.log("mins3 : " + minutes3);
			console.log("sec3 : " + seconds3);

			if (timeLeft <= 0) {

				clearInterval(intervalId);
				//window.location.reload(true);
			}



		}


		//console.log("timeLeft2 : " + timeLeft);

		//console.log("round1---" + _round1EndDate);
		//console.log("now---" + now);
		//console.log("timeLeft" + timeLeft);


	}

	async function closeNav() {
		set_navbarOpen(0);
		//this.setState({ _navbarOpen: 0 });
	}

	async function navbarOpen() {
		set_navbarOpen(1);
		//this.setState({ _navbarOpen: 0 });
	}
	//........................................//
	const { refetch: round1MintedVar } = useContractRead({
		...contract,
		functionName: 'round1Minted',
	})

	const { refetch: round2MintedVar } = useContractRead({
		...contract,
		functionName: 'round2Minted',
	})

	const { refetch: round3MintedVar } = useContractRead({
		...contract,
		functionName: 'round3Minted',
	})

	const { refetch: round1SupplyVar } = useContractRead({
		...contract,
		functionName: 'round1Quota',
	})

	const { refetch: round2SupplyVar } = useContractRead({
		...contract,
		functionName: 'round2Quota',
	})

	const { refetch: round3SupplyVar } = useContractRead({
		...contract,
		functionName: 'round3Quota',
	})



	async function handleConnect() {
		if (chain.id !== 84531) {
			switchNetwork(84531)
		}
		//console.log("_round1Price : " + _round1Price)
		setConnected(true);
		salesAmount1();
		salesAmount2();
		salesAmount3();
		remainingTokens1();
		remainingTokens2();
		remainingTokens3();

		//console.log("_round1Price : " + _round1Price)

		var data = await round1MintedVar();
		set_round1Minted(Number(data.data) / 10 ** 18);
		//console.log("Round1Minted :" + Number(data.data) / 10 ** 18);

		var data1 = await round2MintedVar();
		set_round2Minted(Number(data1.data) / 10 ** 18);
		//console.log("Round2Minted :" + Number(data1.data) / 10 ** 18);

		var data2 = await round3MintedVar();
		set_round3Minted(Number(data2.data) / 10 ** 18);
		//console.log("Round3Minted :" + Number(data2.data) / 10 ** 18);

		var data3 = await round1SupplyVar();
		set_round1Quota(Number(data3.data) / 10 ** 18);
		//console.log("Round1Supply :" + Number(data3.data) / 10 ** 18);

		var data4 = await round2SupplyVar();
		set_round2Quota(Number(data4.data) / 10 ** 18);
		//console.log("Round2Supply :" + Number(data4.data) / 10 ** 18);

		var data5 = await round3SupplyVar();
		set_round3Quota(Number(data5.data) / 10 ** 18);
		//console.log("Round3Supply :" + Number(data5.data) / 10 ** 18);

	}



	useEffect(() => {

		async function fetchData2() {

			setConnected(true);
			salesAmount1();
			salesAmount2();
			salesAmount3();
			remainingTokens1();
			remainingTokens2();
			remainingTokens3();
			set_stateNow(Date.now());

			var data = await round1MintedVar();
			set_round1Minted(Number(data.data) / 10 ** 18);
			//console.log("Round1Minted :" + Number(data.data) / 10 ** 18);

			var data1 = await round2MintedVar();
			set_round2Minted(Number(data1.data) / 10 ** 18);
			//console.log("Round2Minted :" + Number(data1.data) / 10 ** 18);

			var data2 = await round3MintedVar();
			set_round3Minted(Number(data2.data) / 10 ** 18);
			//console.log("Round3Minted :" + Number(data2.data) / 10 ** 18);

			var data3 = await round1SupplyVar();
			set_round1Quota(Number(data3.data) / 10 ** 18);
			//console.log("Round1Supply :" + Number(data3.data) / 10 ** 18);

			var data4 = await round2SupplyVar();
			set_round2Quota(Number(data4.data) / 10 ** 18);
			//console.log("Round2Supply :" + Number(data4.data) / 10 ** 18);

			var data5 = await round3SupplyVar();
			set_round3Quota(Number(data5.data) / 10 ** 18);
			//console.log("Round3Supply :" + Number(data5.data) / 10 ** 18);


			//console.log("_round1StartDate : " + _round1StartDate);
			//console.log("_round1EndDate : " + _round1EndDate);



			return () => {
				// Code to run when the component unmounts
				clearInterval(intervalId);
			};

		}


		if (_connected) {
			fetchData2();

			const id = setInterval(updateCountdown, 1000); // Store the interval ID
			setIntervalId(id); // Store the interval ID in state

			// Code to run when the component unmounts
			clearInterval(intervalId); // Clear the interval on unmount			

		}


	}, [_connected]);




	const { data, refetch, isSuccess } = useContractReads({
		contracts: [
			{ ...contract, functionName: 'owner' },
			{ ...contract, functionName: 'round1Quota' },
			{ ...contract, functionName: 'round2Quota' },
			{ ...contract, functionName: 'round3Quota' },
			{ ...contract, functionName: 'round1Price' },
			{ ...contract, functionName: 'round2Price' },
			{ ...contract, functionName: 'round3Price' },
			{ ...contract, functionName: 'round1Minted' },
			{ ...contract, functionName: 'round2Minted' },
			{ ...contract, functionName: 'round3Minted' },
			{ ...contract, functionName: 'round1StartDate' },
			{ ...contract, functionName: 'round2StartDate' },
			{ ...contract, functionName: 'round3StartDate' },
			{ ...contract, functionName: 'round1EndDate' },
			{ ...contract, functionName: 'round2EndDate' },
			{ ...contract, functionName: 'round3EndDate' },
			{ ...contract, functionName: 'round1CollectedETH' },
			{ ...contract, functionName: 'round2CollectedETH' },
			{ ...contract, functionName: 'round3CollectedETH' },
		]
	},)

	useMemo(() => {

		if (isSuccess === true) {
			set_owner(data[0].result)
			set_round1Quota(Number(data[1].result) / 10 ** 18)
			set_round2Quota(Number(data[2].result) / 10 ** 18)
			set_round3Quota(Number(data[3].result) / 10 ** 18)
			set_round1Price(Number(data[4].result) / 10 ** 18)
			set_round2Price(Number(data[5].result) / 10 ** 18)
			set_round3Price(Number(data[6].result) / 10 ** 18)
			set_round1Minted(Number(data[7].result) / 10 ** 18)
			set_round2Minted(Number(data[8].result) / 10 ** 18)
			set_round3Minted(Number(data[9].result) / 10 ** 18)
			set_round1StartDate(Number(data[10].result) * 1000)
			set_round2StartDate(Number(data[11].result) * 1000)
			set_round3StartDate(Number(data[12].result) * 1000)
			set_round1EndDate(Number(data[13].result) * 1000)
			set_round2EndDate(Number(data[14].result) * 1000)
			set_round3EndDate(Number(data[15].result) * 1000)
			set_round1CollectedETH(Number(data[16].result) / 10 ** 18)
			set_round2CollectedETH(Number(data[17].result) / 10 ** 18)
			set_round3CollectedETH(Number(data[18].result) / 10 ** 18)

		}
	}, [data, isSuccess])

	// eslint-disable-next-line react-hooks/exhaustive-deps
	/*async function setAllProgressbarData() {
		let sa1 = _round1Minted;
		set_round1Minted(sa1);
		//console.log("_round1Minted : " + _round1Minted);
	}*/

	// eslint-disable-next-line react-hooks/exhaustive-deps
	async function salesAmount1() {
		let sa1 = _round1Minted * _round1Price;
		set_salesAmount1(sa1);
		//console.log("sa1 : " + sa1);
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	async function salesAmount2() {
		let sa2 = _round2Minted * _round2Price;
		set_salesAmount2(sa2);
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	async function salesAmount3() {
		let sa3 = _round3Minted * _round3Price;
		set_salesAmount3(sa3);
		//console.log("_round3Minted : " + _round3Minted);
		//console.log("_round3Price : " + _round3Price);
		//console.log("sa3 : " + sa3);
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	async function remainingTokens1() {
		let remainingTokens1 = _round1Quota - _round1Minted;
		set_remainingTokens1(remainingTokens1);
		console.log("remainingTokens1 :" + remainingTokens1);
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	async function remainingTokens2() {
		let remainingTokens2 = _round2Quota - _round2Minted;
		set_remainingTokens2(remainingTokens2);
		console.log("remainingTokens2 :" + remainingTokens2);

	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	async function remainingTokens3() {
		let remainingTokens3 = _round3Quota - _round3Minted;
		set_remainingTokens3(remainingTokens3);
		console.log("remainingTokens3 :" + remainingTokens3);

	}


	return (
		<div class="allWrap">
			<div class="light">
				<div class="cont2">

					{_navbarOpen < 1 ?
						(<div class="miniBarMain">
							<div class="logoDiv"><img onClick={website} class="logo" src={logo} /></div>

							<div class="navbarMain"><img class="navbar" onClick={navbarOpen} src={navbar} /></div>
						</div>) :
						(<div class="miniBarMain">
							<div class="logoDiv"><img onClick={website} class="logo" src={logo} /></div>

							<div class="navbarMain"><img class="navbar" onClick={closeNav} src={wrong} /></div>
						</div>)}

					<div class="headers">

						<div class="h1">

							<div class="logoDiv" onClick={website}><img class="logo" src={logo} /></div>

							<div class="connect">
								<div id="fontSize" onClick={() => window.location.href = '/'}>HOME</div>
								<div id="fontSize"><span class="activeDiv">IDO</span></div>
								<div id="fontSize" onClick={docs}>DOC</div>
							</div>

							<div class="right">

								<div class="connect2">
									<Web3Button />
								</div>

							</div>

						</div>

					</div>

					<Link to="gallery" spy={true} smooth={true} duration={550}>


						{_navbarOpen > 0 ?
							(<div class="littleNav">
								<div id="fontSize" onClick={() => window.location.href = '/'}>HOME</div>
								<div id="fontSize" onClick={() => window.location.href = 'ido'}><span class="activeDiv">IDO</span></div>
								<div id="fontSize" onClick={docs}>DOC</div>
								<div class="connect2">
									<Web3Button />
								</div>

							</div>) : null}
					</Link>
				</div>

				<img class="bannerLine" src={bannerLine} />

				<div class="boxWrap2Story">

					<div class="aboutA">

						<div class="storyCon">
							<div class="conT3">Understanding $MKK</div>
							<p>$MKK serves as the principal currency within the Crazy Monkey King ecosystem. This digital currency is
								designed to facilitate transactions, interactions, and engagement, becoming an essential tool for players to
								navigate the game's multifaceted world.</p>

						</div>

						<img src={nft} />


					</div>

				</div>


				<div class="boxMain">
					<div class="eachBox">
						<div class="roundH"> <img src={money} /> 1st Round</div>

						{!_round1StartStatus ?
							(<div class="timeMain">
								<div class="complete">Round starts on {_formattedDate1}<br/> (GMT)
								</div>
							</div>) :

							(<>{_complete1 ?

								(<div class="timeMain">
									<div class="complete">COMPLETED<img class="verify" src={verify} /></div>
								</div>) :

								(

									<div class="timeMain">

										<div class="timeMain2">
											<div class="time">{_hours}</div>
											<div class="timeN">HOURS</div>
										</div>

										<div class="timeMain2">
											<div class="time">{_minutes}</div>
											<div class="timeN">MINUTES</div>
										</div>

										<div class="timeMain2">
											<div class="time">{_seconds}</div>
											<div class="timeN">SECONDS</div>
										</div>
									</div>

								)}</>)}

						<div class="detailsCon">
							<p>
								<div class="special">{_round1Quota}</div>
								<div>Sale Tokens</div>
							</p>
							<p>
								<div class="special">{_round1Price} ETH</div>
								<div>Sale Price</div>
							</p>
						</div>


						<div class="detailsCon">
							<p>
								<div class="special">{(Number(_round1CollectedETH)).toFixed(6)} ETH</div>
								<div>Sales Amount</div>
							</p>
							<p>
								<div class="special">100%</div>
								<div>TEG</div>
							</p>
						</div>

						<div class="buyBox">
							<div class="buyBoxCo2">
								<div class="special">$MKK Contract <img src={wallet}></img></div>
								<div></div>
							</div>

							<div class="tinyBox">
								<p>
									<div>Remaining Tokens</div>
									<div>{(Number(_remainingTokens1)).toFixed(2)} $MKK</div>
								</p>

								<p>
									<div>Funded Amount</div>
									<div>{_round1CollectedETH} ETH</div>
								</p>

							</div>
						</div>

						<div class="tinyBox2">

							<Round1 />

						</div>

					</div>
					<div class="eachBox">
						<div class="roundH"> <img src={money} /> 2nd Round</div>

						{!_round2StartStatus ?
							(<div class="timeMain">
								<div class="complete">Round starts on {_formattedDate2} <br/> (GMT)
								</div>
							</div>) :

							(<>{_complete2 ?

								(<div class="timeMain">
									<div class="complete">COMPLETED<img class="verify" src={verify} /></div>
								</div>) :
								(<div class="timeMain">

									<div class="timeMain2">
										<div class="time">{_hours2}</div>
										<div class="timeN">HOURS</div>
									</div>

									<div class="timeMain2">
										<div class="time">{_minutes2}</div>
										<div class="timeN">MINUTES</div>
									</div>

									<div class="timeMain2">
										<div class="time">{_seconds2}</div>
										<div class="timeN">SECONDS</div>
									</div>
								</div>)}</>)}

						<div class="detailsCon">
							<p>
								<div class="special">{_round2Quota}</div>
								<div>Sale Tokens</div>
							</p>
							<p>
								<div class="special">{_round2Price} ETH</div>
								<div>Sale Price</div>
							</p>
						</div>

						<div class="detailsCon">
							<p>
								<div class="special">{(Number(_round2CollectedETH)).toFixed(6)} ETH</div>
								<div>Sales Amount</div>
							</p>
							<p>
								<div class="special">100%</div>
								<div>TEG</div>
							</p>
						</div>

						<div class="buyBox">
							<div class="buyBoxCo2">
								<div class="special">$MKK Contract <img src={wallet}></img></div>
								<div></div>

							</div>

							<div class="tinyBox">
								<p>
									<div>Remaining Tokens</div>
									<div>{(Number(_remainingTokens2)).toFixed(2)} $MKK</div>
								</p>

								<p>
									<div>Funded Amount</div>
									<div>{_round2CollectedETH} ETH</div>
								</p>

							</div>
						</div>

						<div class="tinyBox2">
							<Round2 />
						</div>
					</div>

					<div class="eachBox">
						<div class="roundH"><img src={money} /> 3rd Round</div>

						{!_round3StartStatus ?
							(<div class="timeMain">
								<div class="complete">Round starts on {_formattedDate3} <br/> (GMT)
								</div>
							</div>) :

							(<>{_complete3 ?

								(<div class="timeMain">
									<div class="complete">COMPLETED<img class="verify" src={verify} /></div>
								</div>) :

								(<div class="timeMain">

									<div class="timeMain2">
										<div class="time">{_hours3}</div>
										<div class="timeN">HOURS</div>
									</div>

									<div class="timeMain2">
										<div class="time">{_minutes3}</div>
										<div class="timeN">MINUTES</div>
									</div>

									<div class="timeMain2">
										<div class="time">{_seconds3}</div>
										<div class="timeN">SECONDS</div>
									</div>
								</div>)}</>)}


						<div class="detailsCon">
							<p>
								<div class="special">{_round3Quota}</div>
								<div>Sale Tokens</div>
							</p>
							<p>
								<div class="special">{_round3Price} ETH</div>
								<div>Sale Price</div>
							</p>
						</div>

						<div class="detailsCon">
							<p>
								<div class="special">{(Number(_round3CollectedETH)).toFixed(6)} ETH</div>
								<div>Sales Amount</div>
							</p>
							<p>
								<div class="special">100%</div>
								<div>TEG</div>
							</p>
						</div>

						<div class="buyBox">
							<div class="buyBoxCo2">
								<div class="special">$MKK Contract<img src={wallet} /></div>
								<div></div>
							</div>

							<div class="tinyBox">
								<p>
									<div>Remaining Tokens</div>
									<div>{(Number(_remainingTokens3)).toFixed(2)} $MKK</div>
								</p>

								<p>
									<div>Funded Amount</div>
									<div>{_round3CollectedETH} ETH</div>
								</p>

							</div>
						</div>

						<div class="tinyBox2">

							<Round3 />

						</div>
					</div>
				</div>

				<div class="box3">

					<div className="App">
						<ProgressChart round1Supply={_round1Quota} round2Supply={_round2Quota} round3Supply={_round3Quota} round1Minted={_round1Minted} round2Minted={_round2Minted} round3Minted={_round3Minted} />
					</div>
				</div>


				<Footer />

			</div >
		</div >
	)

}
export default IDO;
